import React from "react"
import { Link } from "gatsby"
import { withNamespaces } from "react-i18next";

import Layout from "../components/layout"
import SEO from "../components/seo"
import NavBar from "../components/navbar"
import Footer from "../components/footer"
import Loader from "../components/loader"
import Particles from "../components/particles"

import {Helmet} from "react-helmet";

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]',{
		speed: 1000,
		speedAsDuration: true,
		easing: 'easeInOutCubic'
	})
}

const faqContent = [
    {
        key:"ux",
        order : 1,
        icon: "Gamepad",
        content : 10
    },
    {
        key:"tech",
        order : 0,
        icon: "Phone-Wifi",
        content : 4
    },
    {
        key:"universe",
        icon: "Globe",
        content : 3
    },
    {
        key:"school",
        icon: "University",
        content : 3
    },
    {
        key:"company",
        icon: "Building",
        content : 2
    },
    {
        key:"buz",
        icon: "Haunted-House",
        content : 1
    }
]

class FAQ extends React.Component {

    componentDidMount() {
        window.location.replace("https://www.atlantide.io/");
    }    
    
    state = {
        filter:"",
        visible: []
    }

    lookInString(str, word) {
        return str.includes(word);
    }

    toogle(elementName) {
        let found = false;
        let result = this.state.visible.filter((element) => { 
            found = found | (elementName == element );

            return elementName != element;
        });

        if(!found) {
            result.push(elementName)
        }

        this.setState({visible : result})

        console.log(result);
    }

    render() {
        const {t, i18n} = this.props;

        return (
            <Layout>
                <SEO title="FAQ" />  
                <Loader />
                
                <div id="" className="hero is-relative faq">
                    <NavBar siteTitle="Atlantide IO" foceSolide/>
                    <section id="faq" className="section is-medium">
                        <div className="container">
                            
                            <div className="section-title-wrapper">
                                <div className="bg-number"><i className="material-icons">help</i></div>
                                <h2 className="section-title-landing has-text-centered dark-text">{t('title')}</h2>
                            </div>

                            <div id="filter">
                                <i className={"im im-icon-Magnifi-Glass2"}></i>
                                <input type="text" name="filter" placeholder={t('search_placeholder')} value={this.state.filter} onChange={(e) => { this.setState({filter:e.target.value})}} />
                            </div>

                            <div id="faq-content">
                            {
                                faqContent.map( (categoryContent) => {
                                    return (
                                        <div className="category">
                                            <div className="icon-subtitle"><i className={"im im-icon-"+categoryContent.icon}></i></div>
                                            <div className="title quick-feature is-handwritten text-bold">
                                                <h1>{t(categoryContent.key+".title")}</h1>
                                            </div>
                                            <div className="title-divider is-small"></div>
                                            <div className="answers">
                                                {
                                                    [...Array(categoryContent.content)].map((element, i) => {

                                                        if(this.state.filter && ! (this.lookInString( t(categoryContent.key+"."+(i+1)+".title"), this.state.filter) || this.lookInString( t(categoryContent.key+"."+(i+1)+".value"), this.state.filter) ) ) {
                                                            return null;
                                                        }
                                                        
                                                        return (
                                                            <div className="answer">                                                        
                                                                <h2 onClick={() => { this.toogle(categoryContent.key+"."+(i+1)) }}>{t(categoryContent.key+"."+(i+1)+".title")}</h2>
                                                                <p className={this.state.visible.includes(categoryContent.key+"."+(i+1))?"":"hidden"}>{t(categoryContent.key+"."+(i+1)+".value")}</p>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            </div>
                        </div>
                    </section>
                </div>


                 

                <Footer />
            </Layout>
        )

    } 
}

export default withNamespaces("FAQ")(FAQ);
