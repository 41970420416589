import React, {Component} from 'react'
import ParticlesJS from 'react-particles-js'

import particleConfig from "../assets/config/particles-config.json"

class Particles extends Component {

    shouldComponentUpdate() {
        return false;
    }

    render() {
        return (
            <ParticlesJS 
                params={(this.props.params)?this.props.params:particleConfig} 
                className="particles-js"
            />
        )
    }
}

export default Particles
